<template>
  <div>
    <div class="home-footer">
      <b-container>
        <b-row class="px-1">

          <b-col
            cols="12"
            lg="3"
            sm="6"
            class="mb-1"
          >
            <span class="footer-title">
              特色功能
            </span>
            <b-list-group class="footer-list">
              <b-list-group-item>贊助系統 / Donate System</b-list-group-item>
              <b-list-group-item>推文系統 / Tweet System</b-list-group-item>
              <b-list-group-item>多功能查詢系統 / Multi-query System</b-list-group-item>
              <b-list-group-item>註冊系統 / Register System</b-list-group-item>
            </b-list-group>
          </b-col>

          <b-col
            cols="12"
            lg="3"
            sm="6"
            class="mb-1"
          >
            <span class="footer-title">
              贊助功能
            </span>
            <b-list-group class="footer-list">
              <b-list-group-item>業界首創－首儲功能</b-list-group-item>
              <b-list-group-item>手遊、PC、平板通用</b-list-group-item>
              <b-list-group-item>客製化服務</b-list-group-item>
              <b-list-group-item>贊助紀錄－透明、清楚</b-list-group-item>
              <b-list-group-item>只需要一次</b-list-group-item>
              <b-list-group-item>更多意想不到的功能</b-list-group-item>
            </b-list-group>
          </b-col>

          <b-col
            cols="12"
            lg="3"
            sm="6"
            class="mb-1"
          >
            <span class="footer-title">
              推文系統
            </span>
            <b-list-group class="footer-list">
              <b-list-group-item>業界首創－自訂驗證規則</b-list-group-item>
              <b-list-group-item>推文＆附件</b-list-group-item>
              <b-list-group-item>推文審核</b-list-group-item>
              <b-list-group-item>審核規則、客服回應</b-list-group-item>
              <b-list-group-item>全新－自訂主題</b-list-group-item>
            </b-list-group>
          </b-col>

          <b-col
            cols="12"
            lg="3"
            sm="6"
            class="mb-1"
          >
            <span class="footer-title">
              多功能查詢系統
            </span>
            <b-list-group class="footer-list">
              <b-list-group-item>自定義驗證</b-list-group-item>
              <b-list-group-item>資料匯入</b-list-group-item>
              <b-list-group-item>多平台支援</b-list-group-item>
              <b-list-group-item>主題設定</b-list-group-item>
            </b-list-group>
          </b-col>

        </b-row>

      </b-container>
    </div>

    <p class="footer">
      <span> COPYRIGHT  © 2013 - {{ new Date().getFullYear() }}
        <b-link
          class="ml-25 text-warning"
          href="#創站系統"
        >
          GameHost
        </b-link>
        <span class="allap">, All rights Reserved</span>
      </span>
    </p>
  </div>
</template>

<script>
import {
  BLink, BCol, BRow, BContainer, BListGroup, BListGroupItem,
} from 'bootstrap-vue'

export default {
  components: {
    BLink,
    // BImg,
    BContainer,
    BCol,
    BRow,
    BListGroup,
    BListGroupItem,
  },
}
</script>

<style lang="scss" scoped>
.home-footer{
  background-color: #323232f4;//#161d31
  padding: 50px 0;
  // text-align:center;
  // font-size: 1.2rem;
  .footer-title {
    // font-size: 21px !important;
    color:white;
    font-weight: bold;
    font-size: 1.5rem;
  }
  .footer-list{
    color: rgb(162, 162, 162);
    .list-group-item {
      background-color: transparent;
      padding: 0;
      border: none;
      &:hover {
        background-color: transparent;
      }
    }
  }
}

.footer {
  align-content: center;
  z-index: 999;
  background-color: #2b2b2b;
  color: aliceblue;
  padding: 8px;
  display: flex;
  justify-content: center;
}
.footer-row {
  display: flex;
  justify-content: center;
  align-content: center;
  .footer-col-one {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20%;
    width: 80%;
  }
  .footer-col-two {
    display: flex;
    justify-content: end;
    align-content: flex-end;
  }
}
@media screen and (max-width: 870px) {
  .footer{
    display: flex;
    justify-content: center;
    align-content: center;
  }
  .footer-row {
    .footer-col-one {
      margin: 0;
      padding: 0;
      min-width: 100%;
    }
    .footer-col-two {
      display: none;
    }
  }
}

@media screen and (max-width: 413px) {
  .allap {
    display: none;
  }
}
</style>
