<template>
  <div class="home-content">
    <home-top id="top" />

    <!-- 特色功能 -->
    <div
      id="特色功能"
      class="section-two"
    >
      <b-container class="section-container">
        <b-row
          class="section-header my-2"
          data-aos="fade-right"
        >
          <b-col
            cols="12"
            md="8"
          >
            <div
              class="d-flex align-items-end page-title"
              data-aos="fade-right"
            >
              <b-img
                :src="require('@/assets/images/webp/puzzle.webp')"
                class="mr-2"
                alt="特色功能"
              />
              <h2>特色功能</h2>
            </div>

            <hr>
          </b-col>
        </b-row>

        <b-row class="section-content">
          <b-col
            cols="12"
            md="4"
            class="mb-2 mb-md-0"
          >
            <b-card
              class="feature-card mx-1 feature-card-1"
              data-aos="zoom-out"
            >
              <div class="p-1 feature-header">
                <b-img
                  :src="require('@/assets/images/webp/investment.webp')"
                  alt="贊助系統"
                />
                <h3>贊助系統</h3>
              </div>

              <hr>

              <b-card-text class="mt-2 feature-content">
                <h4 class="feature-content-text">
                  多達11家金流串接
                </h4>
                <h4 class="feature-content-text">
                  綠界、萬事達...等熱門金流皆可使用
                </h4>
              </b-card-text>

              <hr>

              <b-card-footer class="feature-footer">
                <div class="footer-btn-group">
                  <div class="footer-btn">
                    <a
                      class="btn"
                      href="#贊助系統"
                    >了解更多
                    </a>
                  </div>

                  <div class="footer-img">
                    <b-img
                      :src="require('@/assets/images/webp/up-chevron.webp')"
                      class="img"
                      alt="了解更多"
                    />
                  </div>
                </div>
              </b-card-footer>
            </b-card>
          </b-col>

          <b-col
            cols="12"
            md="4"
            class="mb-2 mb-md-0"
          >
            <b-card
              class="feature-card mx-1 feature-card-2"
              data-aos="zoom-out"
            >
              <div class="p-1 feature-header">
                <b-img
                  :src="require('@/assets/images/webp/writing.webp')"
                  alt="推文系統"
                />
                <h3>推文系統</h3>
              </div>

              <hr>

              <b-card-text class="mt-2 feature-content">
                <h4 class="feature-content-text">
                  推文分享、直播紀錄只需一個系統整合多元化主題設計、審核機制完整
                </h4>
                <h4 class="feature-content-text">
                  設定一次，讓你不再煩惱有沒有發送獎勵
                </h4>
              </b-card-text>

              <hr>

              <b-card-footer class="feature-footer">
                <div class="footer-btn-group">
                  <div class="footer-btn">
                    <a
                      class="btn"
                      href="#推文系統"
                    >了解更多
                    </a>
                  </div>

                  <div class="footer-img">
                    <b-img
                      :src="require('@/assets/images/webp/up-chevron.webp')"
                      class="img"
                      alt="了解更多"
                    />
                  </div>
                </div>
              </b-card-footer>
            </b-card>
          </b-col>

          <b-col
            cols="12"
            md="4"
            class="mb-2 mb-md-0"
          >
            <b-card
              class="feature-card mx-1 feature-card-3"
              data-aos="zoom-out"
            >
              <div class="p-1 feature-header">
                <b-img
                  :src="require('@/assets/images/webp/loupe.webp')"
                  alt="多功能查詢"
                />
                <h3>多功能查詢</h3>
              </div>

              <hr>

              <b-card-text class="mt-2 feature-content">
                <h4 class="feature-content-text">
                  怪物掉寶、道具機率、及時噴寶、BOSS出沒時間......等
                </h4>
                <h4 class="feature-content-text">
                  只要你需要，<span class="feature-content-text-focus">GameHost </span>都有
                </h4>
              </b-card-text>

              <hr>

              <b-card-footer class="feature-footer">
                <div class="footer-btn-group">
                  <div class="footer-btn">
                    <a
                      class="btn"
                      href="#查詢系統"
                    >了解更多
                    </a>
                  </div>

                  <div class="footer-img">
                    <b-img
                      :src="require('@/assets/images/webp/up-chevron.webp')"
                      class="img"
                      alt="了解更多"
                    />
                  </div>
                </div>
              </b-card-footer>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <!-- 贊助系統 -->
    <div
      id="贊助系統"
      class="section-three"
    >
      <b-container class="section-container px-2">
        <b-row
          class="section-header my-2"
          data-aos="fade-right"
        >
          <b-col
            cols="12"
            md="8"
          >
            <div
              class="d-flex align-items-end page-title"
              data-aos="fade-right"
            >
              <b-img
                :src="require('@/assets/images/webp/donation.webp')"
                class="mr-2"
                alt="贊助功能"
              />
              <h2>贊助功能</h2>
            </div>

            <hr>
          </b-col>
        </b-row>

        <b-row
          class="section-title my-2"
          data-aos="fade-right"
        >
          <b-col cols="12">
            <h3>
              提供多達11家金流串接服務，同業中最了解金流的輔助系統。
            </h3>
            <h3>
              贊助金額一目了然，多於複雜的步驟都將省略
            </h3>
          </b-col>
        </b-row>

        <b-row class="section-content">
          <b-col
            v-for="(item,index) in sponsorshipSystem"
            :key="index"
            cols="12"
            md="6"
            lg="4"
            class="content-table"
            data-aos="zoom-in"
          >
            <h3>{{ item.title }}</h3>
            <h4>{{ item.content }}</h4>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <!-- 推文系統 -->
    <div
      id="推文系統"
      class="section-four pt-2"
    >
      <b-container class="bv-example-row section-container px-2">
        <b-row class="section-header">
          <b-col
            cols="12"
            md="8"
          >
            <div
              class="d-flex align-items-end page-title"
              data-aos="fade-right"
            >
              <b-img
                :src="require('@/assets/images/webp/send.webp')"
                class="mr-2"
                alt="推文系統"
              />
              <h2>推文系統</h2>
            </div>
            <hr>
          </b-col>
        </b-row>

        <b-row
          class="section-title my-2"
          data-aos="fade-right"
        >
          <b-col cols="12">
            <h3> 分享連結、直播連結、分送獎勵，一手搞定。</h3>
            <h3> 設定只需一次，即可一勞永逸。</h3>
          </b-col>
        </b-row>

        <b-row class="section-content my-2">
          <b-col
            v-for="(item,index) in tweetingSystem"
            :key="index"
            cols="12"
            md="6"
            lg="4"
            class="p-2"
            data-aos="flip-down"
            data-aos-duration="2000"
          >
            <b-card class="content-card rounded h-100">
              <img
                :src="require(`@/assets/images/items/${ item.image }`)"
                :alt="item.alt"
              >
              <div>
                <h3>{{ item.title }}</h3>
                <h4>{{ item.content }}</h4>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <!-- 查詢系統 -->
    <div
      id="查詢系統"
      class="section-five"
    >
      <b-container
        class="bv-example-row section-container"
      >
        <b-row
          class="section-header my-2"
          data-aos="fade-right"
        >
          <b-col
            cols="12"
            md="8"
          >
            <div class="fivein d-flex align-items-end page-title">
              <b-img
                :src="require('@/assets/images/webp/search.webp')"
                class="mr-2"
                alt="多功能查詢系統"
              />
              <h2>多功能查詢系統</h2>
            </div>
            <hr>
          </b-col>
        </b-row>

        <b-row
          class="section-title my-2"
          data-aos="fade-right"
        >
          <b-col cols="12">
            <h3>全新的多功能查詢系統隆重登場</h3>
            <h3>支援多元格式和平台、一鍵匯入還能自定義帳號驗證、個人化系統主題</h3>
            <h3>讓你做自己系統的主人</h3>
          </b-col>
        </b-row>

        <div class="row1-container">
          <div
            class="box box-down card-1"
            data-aos="zoom-out"
          >
            <h2>資料匯入</h2>
            <p>多元格式支援，檔案上傳、資料庫串接，一鍵匯入您的所有需求</p>
            <div class="text-right">
              <img
                src="https://assets.codepen.io/2301174/icon-karma.svg"
                alt="資料匯入"
              >
            </div>
          </div>

          <div
            class="box card-2"
            data-aos="zoom-out"
          >
            <h2>自定義驗證</h2>
            <p>不管是遊戲帳號、密碼、伺服器，設定簡單方便快速，安全有保證</p>
            <div class="text-right">
              <img
                src="https://assets.codepen.io/2301174/icon-calculator.svg"
                alt="自定義驗證"
              >
            </div>
          </div>

          <div
            class="box box-down card-3"
            data-aos="zoom-out"
          >
            <h2>多平台支援</h2>
            <p>全支援RWD格式，讓玩家手機、PC、平板一樣的體驗，GameHost為您解決所有問題</p>
            <div class="text-right">
              <img
                src="https://assets.codepen.io/2301174/icon-team-builder.svg"
                alt="多平台支援"
              >
            </div>
          </div>
        </div>

        <div
          class="row2-container"
          data-aos="zoom-out"
        >
          <div class="box card-4">
            <h2>主題設定</h2>
            <p>主題設計、不管是文字大小顏色還是背景圖片和影片上傳，全部依照自己喜歡的風格設計</p>
            <div class="text-right">
              <img
                src="https://assets.codepen.io/2301174/icon-supervisor.svg"
                alt="主題設定"
              >
            </div>
          </div>
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
import {
  BContainer, BRow, BCol, BImg, BCard, BCardFooter, BCardText,
} from 'bootstrap-vue'
import HomeTop from './HomeTop.vue'

export default {
  components: {
    HomeTop,

    BImg,
    BCard,
    BCardFooter,
    BCardText,
    BRow,
    BCol,
    // BButton,
    BContainer,
  },
  props: {},
  data() {
    return {
      optionssev: [
        { value: null, text: '選擇服務' },
        { value: '', text: '服務1' },
        { value: '', text: '服務2' },
        { value: '', text: '服務3' },
      ],
      selected: '',
      options: [
        { text: '經銷商', value: 'first', disabled: false },
        { text: '網路', value: 'second', disabled: false },
        { html: 'Item3', value: 'third', disabled: false },
        { text: 'Item 4', value: 'Item 4', disabled: false },
      ],
      sponsorshipSystem: [
        {
          title: '業界首創－首儲功能',
          content: '首儲功能我們是同業中最早開發，\n使用的客戶多達上百間',
        },
        {
          title: '手遊、PC、平板通用',
          content: '版型支援各種樣式版型。\n2.0版本將全數支援RWD',
        },
        {
          title: '客製化服務',
          content: '您的需求就是我們進步的動力。\n有任何問題歡迎詢問客服',
        },
        {
          title: '贊助紀錄－透明、清楚',
          content: '超過十年的開發經驗，清楚明瞭的\nlog，問題一把抓，自己就是技術',
        },
        {
          title: '只需要一次',
          content: '工程人員會定時確認金流方是否正常\n開通，若有問題客服人員會第一時間\n將會協助處理',
        },
        {
          title: '更多意想不到的功能',
          content: '贊助滿額、禮包選擇、單一帳號禮包\n限制、自訂發送專屬道具...等\n更多功能等你來使用',
        },
      ],
      // four
      tweetingSystem: [
        {
          image: 'list.svg',
          alt: '自訂驗證規則',
          title: '業界首創－自訂驗證規則',
          content: '只有你的玩家可以登入留言，不再擔心不是你的玩家來留言。',
        },
        {
          image: 'media.svg',
          alt: '推文附件',
          title: '推文＆附件',
          content: '不只貼文連結，還可以跟圖片一起上傳。',
        },
        {
          image: 'shield.svg',
          alt: '推文審核',
          title: '推文審核',
          content: '推文資料一目了然，使用我們的系統，讓您的客服更輕鬆。',
        },
        {
          image: 'headphone.svg',
          alt: '客服',
          title: '審核規則、客服回應',
          content: '是否通過審核，玩家可以方便查閱，減少更多人力使用。',
        },
        {
          image: 'color-palette.svg',
          alt: '自訂主題',
          title: '全新－自訂主題',
          content: '主題設計、從文字顏色到背景圖示全部都可以在後台自定義。',
        },
      ],
    }
  },
  created() {
    this.observer = new IntersectionObserver(this.onElementObserved, {
      root: this.$el,
      threshold: 0.22,
    })
  },
  mounted() {
    this.$el.querySelectorAll('div[id]').forEach(div => {
      this.observer.observe(div)
    })
  },
  methods: {
    onElementObserved(entries) {
      entries.forEach(({ target, isIntersecting }) => {
        const id = target.getAttribute('id')
        if (isIntersecting) {
          this.$emit('getFocusDiv', id)
        }
      })
    },

    redirectLink() {
      window.location.replace('https://web-hosts.net/auth')
    },
  },
}
</script>

<style lang="scss" scoped>
.home-content {
  width: 100vw;
  overflow-x: hidden;
}

// 贊助系統
.section-two {
  z-index: 2;
  position: relative;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  // background: linear-gradient(1deg, #ae0858 2%, #411395 51%, #1b112c 85%);
  background: linear-gradient(1deg, #33394b 2%, #282038 51%, #1b112c 85%);
  box-shadow: 0 -200px 200px 0 #1b112c;
  .section-container {
    .section-header {
      hr {
        border: 2px solid white;
      }
    }
    .section-content {
      .feature-card {
        min-height: 100%;
        border-radius: 20px;
        .feature-header {
          display: flex;
          align-items: center;
          flex-direction: column;
          img {
            height: 60px;
            margin-bottom: 20px;
          }
          h3 {
            font-size: 2em;
            color: #ffffff;
            font-weight: 800;
            // text-shadow: 4px 4px #558ABB;
          }
        }
        hr{
          border: 1px solid white;
        }
        .feature-content {
          .feature-content-text {
            color:white;
            .feature-content-text-focus {
              color: #fff186;
              // font-size: 60px;
              animation: neon 1.5s ease-in-out infinite alternate;

              @keyframes neon {
                0%,
                100% {
                  color: #ff7b00;
                }
                50% {
                  color: #ffe100;
                }
              }
            }
          }
        }
        .feature-footer {
          border: none;
          .footer-btn-group{
            margin-top: 50px;
            .footer-btn {
              display: flex;
              justify-content: center;
              .btn{
                color: white;
                border-radius: 24px;
                background-color: rgb(21, 94, 159) ;
                // height: 35px;
                opacity: 0;
              }
            }
            .footer-img {
              display: flex;
              justify-content: center;
              .img{
                margin-top: 15px;
                width: 40px;
                // height: 30px;
                opacity: 0;
              }
            }
          }
        }
      }
      .feature-card:hover {
        transform: translateY(-20px);
        transition: all 0.3s ease;
        .feature-footer {
          .footer-btn-group {
            .footer-img{
              .img{
                opacity: 1;
                transition: all 1.5s ease;
              }
            }
            .footer-btn{
              // margin-top: 10%; //20
              transition: all 0.8s ease;
              .btn{
                opacity: 1;
                transition: all 0.8s ease;
              }
              .btn:hover{
                background-color: rgb(99, 146, 196);
                transition: all 0.5s ease;
              }
            }
          }
        }
      }

      .feature-card-1 {
        background: linear-gradient(145deg, #6aa5cf 0%, #cc5c9b 100%);
        box-shadow: 15px 15px 30px rgb(25, 25, 25), -15px -15px 30px rgb(60, 60, 60);
        .feature-header {
          h3 {
            text-shadow: 4px 4px #7949a0;
          }
        }
      }

      .feature-card-2 {
        background: linear-gradient(145deg, #d38049 0%, #dcc05e 100%);
        box-shadow: 15px 15px 30px rgb(25, 25, 25), -15px -15px 30px rgb(60, 60, 60);
        .feature-header {
          h3 {
            text-shadow: 4px 4px #9c7d47;
          }
        }
      }

      .feature-card-3 {
        background: linear-gradient(145deg, #2eafda 0%, #52d369 100%);
        box-shadow: 15px 15px 30px rgb(25, 25, 25), -15px -15px 30px rgb(60, 60, 60);
        .feature-header {
          h3 {
            text-shadow: 4px 4px #558ABB;
          }
        }
      }
    }

    @media screen and (max-width: 767px) {
      .section-content {
        .feature-card {
          .feature-footer {
            .footer-btn-group {
              .footer-img{
                .img{
                  margin-top: 15px;
                  opacity: 1;
                }
              }
              .footer-btn{
                .btn{
                  opacity: 1;
                }
              }
            }
          }
        }

        .feature-card:hover {
          transform: none;
        }
      }
    }
  }
}

// 贊助系統
.section-three {
  min-height: 100vh;
  background: linear-gradient( 1deg, #23557c 2%, #023459 51%, #33394b 85%);
  // background: linear-gradient( 1deg, #4b0276 2%, #411395 51%, #ae0858 85%);
  display: flex;
  justify-content: center;
  align-items: center;
  .section-container {
    .section-header {
      hr {
        border: 2px solid white;
      }
    }
    .section-title {
      h3{
        color: white;
        letter-spacing: 2px;
      }
    }

    .section-content {
      .content-table {
        h3{
          font-weight:bold;
          color: white;
          margin-bottom: 20px;
        }
        h4{
          white-space:pre-line;
          color: white;
        }
      }
    }

    @media screen and (min-width: 992px) {
      .section-content {
        .content-table {
          padding: 50px;
        }
        .content-table:nth-child(1){
          border-bottom: 1px solid;
          border-right: 1px solid;
        }
        .content-table:nth-child(2){
          border-bottom: 1px solid;
          border-right: 1px solid;
          border-left: 1px solid;
        }
        .content-table:nth-child(3){
          border-bottom: 1px solid;
          border-left: 1px solid;
        }
        .content-table:nth-child(4){
          border-top: 1px solid;
          border-right: 1px solid;
        }
        .content-table:nth-child(5){
          border-top: 1px solid;
          border-right: 1px solid;
          border-left: 1px solid;
        }
        .content-table:nth-child(6){
          border-top: 1px solid;
          border-left: 1px solid;
        }
      }
    }

    @media screen and (min-width: 769px) and (max-width: 992px) {
      .section-content {
        .content-table {
          padding: 50px;
        }
        .content-table:nth-child(1){
          border-bottom: 1px solid;
          border-right: 1px solid;
        }
        .content-table:nth-child(2){
          border-bottom: 1px solid;
          border-left: 1px solid;
        }
        .content-table:nth-child(3){
          border-bottom: 1px solid;
          border-right: 1px solid;
        }
        .content-table:nth-child(4){
          border-bottom: 1px solid;
          border-left: 1px solid;
        }
        .content-table:nth-child(5){
          border-right: 1px solid;
        }
        .content-table:nth-child(6){
          border-left: 1px solid;
        }
      }
    }

    @media screen and (max-width: 768px) {
      .section-content {
        .content-table {
          border-bottom: 1px solid;
          padding: 50px;
        }
        .content-table:last-child{
          border-bottom: none;
        }
      }
    }
  }
}

// 推文系統
.section-four{
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(3deg, #6a9dd3  10%, #007bff 30%, #23557c 85%);
  // background: linear-gradient(3deg, #1926b8 10%, #313fbe 30%, #4b0276 85%);
  min-height: 100vh;
  .section-container {
    .section-header {
      hr {
        border: 2px solid white;
      }
    }
  }
  .section-title {
    h3{
      color: white;
      letter-spacing: 2px;
    }
  }
  .section-content {
    .content-card {
      min-height: 200px;
      border: solid white 3px;
      background-color: rgba(255, 255, 255, 0);
      display: flex;
      flex-direction: row;
      justify-content: center;
      img{
        margin-bottom: 2em;
      }
      h3{
        font-weight:bold;
        color: white;
        margin-bottom: 20px;
      }
      h4{
        white-space:pre-line;
        color: white;
      }
    }
    .content-card:hover {
      transform: translateY(-10px);
      box-shadow: 0 4px 30px 0 rgba(228, 232, 236, 0.24);
      border: solid white 3px;
      background-color: rgba(255, 255, 255, 0.2);
    }
    img{
      height: 80px;
    }
  }
}

// 查詢系統
.section-five{
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(5deg, #092039 3%, #224871  51%, #6a9dd3  85%);
  // background: linear-gradient(5deg, #000000 3%, #050137  51%, #1926b8  85%);
  min-height: 100vh;

  .section-container {
    .section-header {
      hr {
        border: 2px solid white;
      }
    }
  }

  .section-title {
    h3{
      color: white;
      letter-spacing: 2px;
    }
  }

  .box {
    border-radius: 20px;
    box-shadow: 0px 30px 40px -20px #1f1f1f;
    padding: 30px;
    margin: 20px 8px;
    p {
      color: hsl(0, 0%, 100%);
      font-size: 18px;
      line-height: 22px
    }
    // img {
    //   // float: right;
    //   // bottom: 0;
    //   right: 0;
    //   display: flex;
    //   justify-content: end;
    //   // align-items: center;
    // }

    h2 {
      color: hsl(0, 0%, 100%);
      font-size: 30px;
      font-weight: 600;
      text-shadow: 3px 3px #4d4d4d;
    }
  }

  .box:hover {
    transform: translateY(-20px);
    transition: all 0.3s ease;
  }

  .card-1 {
    background: linear-gradient(180deg, #5fbcfe 0%, #7676ef 80%);
  }
  .card-2 {
    background: linear-gradient(180deg, #f6a772 0%, #ffd439 100%);
  }
  .card-3 {
    background: linear-gradient(180deg, #2eafda 0%, #52d369 100%);
  }
  .card-4 {
    background: linear-gradient(180deg, #e3b0fa 0%, #cc5c9b 100%);
  }

  @media (min-width: 950px) {
    .row1-container {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      z-index: 3;
    }

    .row2-container {
      // background-color: #4586ff;
      z-index: 2;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
    }
    .box-down {
      position: relative;
      top: 150px;
    }
    .box {
      width: 30%;
      margin: 30px;
    }
  }
}

// 創站系統
.section-six{
  background: linear-gradient(5deg, #000000 3%, #070f16  51%, #092039  85%);
  box-shadow: 0 -200px 200px 0 #092039;
  h2 {
    font-size: 72px;
    color: white;
  }
  h4 {
    font-size: 24px;
    color: white;
    animation: fadein 3s;
  }
  h3 {
    font-size: 21px;
    color: white;
  }
  .section-content-1 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; // 150 90
    position: relative;
    margin: 0 auto;
    z-index: 3;
      .content {
        display: flex;
        position: sticky;
        z-index: 1;
        top: 20%;
        margin: 0 auto;
        flex-direction: column;
        justify-content: center;
        .content-title {
          text-shadow: 5px 2px #565656, 2px 4px #565656, 3px 5px #565656;
        }
        .startbt {
          --width: 110px;
          --height: 45px;
          --button-color: #f09819;
          width: var(--width);
          height: var(--height);
          background: var(--button-color);
          cursor: pointer;
          position: relative;
          text-align: center;
          border-radius: 10px;
          text-decoration: solid;
          transition: background box-shadow 0.3s;
          -webkit-box-shadow: 4px 8px 19px -3px #f09819;
          box-shadow: 0px 0px 20px -3px #f09819;
          text-shadow: 0 0 .4em rgb(255, 255, 255);
        }
        .startbt::before {
          position: absolute;
          content: attr(data-tooltip);
          background-color: var(--button-color);
          font-size: 0.9rem;
          color: #111;
        }

        .startbt::after {
          position: absolute;
          content: '';
          width: 0;
          height: 0;
          border: 10px solid transparent;
          border-top-color: var(--button-color);
        }

        .startbt::after,.startbt::before {
          opacity: 0;
          visibility: hidden;
          transition: all 0.5s;
        }

        .text {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .button-wrapper,.text,.icon {
          overflow: hidden;
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          color: #fff;
        }

        .text {
          top: 0
        }

        .text,.icon {
          transition: top 0.45s;
        }

        .icon {
          color: #fff;
          top: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .startbt:hover {
          background: #4586ff;
          box-shadow: 0px 0px 20px -3px #4586ff;
        }

        .startbt:hover .text {
          top: -100%;
        }

        .startbt:hover .icon {
          top: 0;
        }
      }
      .content-text {
        .text-focus {
          color: #fff186;
          // font-size: 60px;
          animation: neon 1.5s ease-in-out infinite alternate;

          @keyframes neon {
            0%,
            100% {
              color: #ff7b00;
            }
            50% {
              color: #ffe100;
            }
          }
        }
      }
  }
  .section-content-2{
    height: 80vh; // 120 100
    background-color: rgb(0, 0, 0);
    position: relative;
    margin: 0 auto;
    z-index: 3;
    .content{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin:0 auto 0 auto;
      padding: 1px;
    }
  }
  @media screen and (min-width: 800px) {
    h2{
      font-size: 148px;
      color: white;
      animation: fadein 3s;
    }
    h4{
      font-size: 36px;
      color: white;
      animation: fadein 3s;
    }
    h3{
      font-size: 28px;
      color: white;
      animation: fadein 3s;
    }
  }
  @media screen and (min-width: 1400px) {
    h2{
      font-size: 200px;
      color: white;
    }
    h3{
      font-size: 40px;
      color: white;
    }
    h4{
      font-size: 56px;
      color: white;
    }
  }
  @keyframes fadedin {
    0% { opacity: .5 }
    100% { opacity: 1 }
  }
  @keyframes fadedout {
    0% { opacity: 1 }
    100% { opacity: 0 }
  }
}

.page-title {
  h2 {
    color: white;
    margin-bottom: 0;
    text-shadow: 5px 2px #775324, 2px 4px #775324, 3px 5px #775324;
  }
  img {
    height: 50px;
  }
  @media screen and (max-width: 572px) {
    h2 {
      font-size: 35px;
    }
  }
  @media screen and (min-width: 572px) and (max-width: 1200px) {
    h2 {
      font-size: 40px;
    }
  }
  @media screen and (min-width: 1200px) {
    h2 {
      font-size: 50px;
    }
    img {
      height: 60px;
    }
  }
}

.search-content{
  margin-left: 50px;
  line-height: 2;
  @media screen and (max-width: 1160px) {
    margin-left: 30px;
  }
  @media screen and (max-width: 900px) {
    margin-left: 20px;
  }
  @media screen and (max-width: 768px) {
    margin-left: 20px;
    font-size: 20px;
  }
  @media screen and (max-width: 610px) {
    margin-left: 10px;
    font-size: 15px;
  }
  @media screen and (max-width: 460px) {
    margin-left: 0px;
    font-size: 15px;
  }
  @media screen and (min-width: 768px) {
    font-size: 28px;
  }
}

:root{
  scroll-padding-top:3rem;
}
</style>
