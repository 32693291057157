<template>
  <div class="client-home">
    <div>
      <home-navbar :get-div="foucusDiv" />
      <home-content @getFocusDiv="foucusDiv=$event" />
      <home-footer />
    </div>
  </div>
</template>

<script>
import HomeContent from './HomeContent.vue'
import HomeNavbar from './Navbar.vue'
import HomeFooter from './HomeFooter.vue'

export default {
  components: {
    HomeContent,
    HomeNavbar,
    HomeFooter,
  },
  data() {
    return {
      foucusDiv: '',
    }
  },
  setup() {
    const href = window.location.origin
    window.history.replaceState(href, 'home', '/home.html')
  },
}
</script>
