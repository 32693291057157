<template>
  <div
    id="top"
    class="navber"
  >
    <nav>
      <div class="wrapper">
        <label
          for="menu-btn"
          class="btn menu-btn"
        >
          <feather-icon
            size="30"
            icon="ListIcon"
            class="menuicon"
          />
        </label>

        <div class="logo">
          <b-link
            class="brand-logo"
            @click="scrollToTop"
          >
            <b-img
              :src="require('@/assets/images/webp/GAMEHOST-L(白字).webp')"
              fluid
              class="logobimg"
              alt="logo"
            />
            <!-- {{ getDiv }} -->
          </b-link>
        </div>

        <input
          id="menu-btn"
          type="radio"
          name="slider"
        >
        <input
          id="cancel-btn"
          type="radio"
          name="slider"
        >

        <ul class="nav-links">
          <label
            for="cancel-btn"
            class="btn cancel-btn"
          >
            <feather-icon
              size="30"
              icon="XIcon"
              class="mr-50"
            />
          </label>

          <li>
            <a href="#特色功能">
              <span>
                特色功能
              </span>
            </a>
          </li>
          <li>
            <a href="#贊助系統">
              <span>
                贊助系統
              </span>
            </a>
          </li>
          <li>
            <a href="#推文系統">
              <span>
                推文系統
              </span>
            </a>
          </li>
          <li>
            <a href="#查詢系統">
              <span>
                查詢系統
              </span>
            </a>
          </li>
          <!-- <li>
            <a href="#創站系統">
              <span>
                創站系統
              </span>
            </a>
          </li> -->
        </ul>
      </div>
    </nav>

    <div class="gotop">
      <a
        href="#"
        @click="scrollToTop"
      >
        <b-img
          :src="require('@/assets/images/items/upload.svg')"
          class="upload"
          alt="scrollToTop"
        />
      </a>
    </div>
  </div>
</template>

<script>
import { useWindowScroll } from '@vueuse/core'
import { BImg, BLink } from 'bootstrap-vue'

export default {
  components: {
    BImg,
    BLink,
  },
  props: {
    getDiv: {
      type: String,
      require: false,
      default: 'one',
    },
  },
  // data() {
  //   return {
  //     isShow: false,
  //   }
  // },
  watch: {
    getDiv(foucus, notfoucus) {
      if (foucus !== '') {
        const els = this.$el.querySelectorAll(`li a[href="#${foucus}"]`)
        for (let i = 0; i < els.length; i += 1) {
          els[i].classList.add('active')
        }
      }
      if (foucus === 'top') {
        const els = this.$el.querySelectorAll(`li a[href="#${foucus}"]`)
        for (let i = 0; i < els.length; i += 1) {
          els[i].classList.remove('active')
        }
      }
      if (notfoucus) {
        const els = this.$el.querySelectorAll(`li a[href="#${notfoucus}"]`)
        for (let i = 0; i < els.length; i += 1) {
          els[i].classList.remove('active')
        }
      }
    },
  },
  mounted() {
    // this.show = true
    window.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll() {
      // console.log(window.scrollY)
      if (window.scrollY > 100) {
        this.$el.querySelector('nav').style.background = 'rgba(0, 0, 0)'
        this.$el.querySelector('nav .wrapper').style.height = '60px'
        this.$el.querySelector('nav .wrapper').style.transition = 'all 0.5s ease'
        this.$el.querySelector('.gotop').style.display = 'block'
        this.$el.querySelector('nav').style.animation = 'fadeInAnimation ease 0s'
      }
      if (window.scrollY <= 100 || !window.scrollY) {
        // this.$el.querySelector('nav').style.transition = '0s'
        this.$el.querySelector('nav').style.background = 'rgba(22, 29, 49, 0)'
        this.$el.querySelector('nav .wrapper').style.height = '85px'
        this.$el.querySelector('.gotop').style.display = 'none'
        this.$el.querySelector('nav').style.animation = 'fadeInAnimation ease 0s'
      }
    },
  },

  setup() {
    const { y } = useWindowScroll()

    const scrollToTop = () => {
      const rootEle = document.documentElement
      rootEle.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }

    return { y, scrollToTop }
  },
}
</script>
<style lang="scss" scoped>
// 電腦版面
// @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
//   font-family: 'Poppins', sans-serif;
}
nav{
  animation: fadeInAnimation ease 12s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  position: fixed;
  z-index: 1000; //顯示權重
  width: 100%;
}

.wrapper {
  position: relative;
  max-width: 100%;
  padding: 0px 10px;
  height: 85px;
  // line-height: 70px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .logo {
    .brand-logo {
      img {
        max-height: 45px;
        margin-bottom: 15px;
        margin-top: 15px;
        margin-right: 50px;
      }
    }
  }
  .nav-links{
    display: inline-flex;
  }
}

.nav-links {
  li {
    list-style: none;
    a {
      color: #ffffff;
      text-decoration: none;
      font-size: 20px;
      font-weight: 500;
      padding: 9px 15px;
      border-radius: 5px;
      transition: all 0.3s ease;
      display: inline-block;
      text-transform: uppercase;
      transform: skew(-21deg);
      background-size: 200% auto;

      span {
        display: inline-block;
        transform: skew(21deg);
      }

      &.active {
        box-shadow: 0px 0px 14px -7px #f09819;
        background-image: linear-gradient(45deg, #775324 0%, #F09819  51%, #775324  100%);
      }
    }
    a:hover{
      box-shadow: 0px 0px 14px -7px #f09819;
      background-image: linear-gradient(45deg, #775324 0%, #F09819  51%, #775324  100%);
      color: #fff;
      background-position: right center;
      text-decoration: none;
    }
  }
}

.nav-links>li:last-child:after {
  display: none;
}

.nav-links .mobile-item{
  display: none;
}
@media screen and (min-width: 971px) {
  .nav-links > li::after {
    content: "/";
    display: inline-block;
    font-size: 18px;
    font-size: 1.8rem;
    color: rgba(255,255,255,0.2);
    top: 0;
    right: -3px;
  }
}

.wrapper .btn{
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  display: none;
}
.wrapper .cancel-btn{
  color: #fff;
  position: absolute;
  right: 20px;
  top: 20px;
  display: none;
}
.b-nav-dropdown .dropdown-toggle::after {
  display: none !important;
}
// 手機版面
@media screen and (max-width: 970px) {
  .test{
    display: flex;
    justify-content: start;
    align-items: start;
    // margin-left: 50px;
  }
  .wrapper{
    width: 100%;
    z-index: 10;
  }
  .menu-btn{
    display: flex;
    // margin-right: 20px;
    .menuicon{
      margin-right: 20px;
    }
  }
  .nav-links li a{
    padding: 0px 0px;
  }
  .nav-links li a span{
    border-radius: 10px 10px 0 0px;
    border-bottom: 1px solid white;
  }
  .wrapper .btn{
    display: block;
  }
  .wrapper .logo{
    margin-bottom: 40px;
    height: 30px;
    margin-left: auto;
    margin-right:auto; //login自動置中
     // display: flex;
    // margin-right:10px;
    // justify-content: center;
    // align-items: center;
    .logobimg{
      height: 40px;
      // size: 10px;
    }
  }
  .wrapper .logo a{
    color: #ffffff;
    font-size: 30px;
    font-weight: 300;
    text-decoration: none;
  }

  .wrapper .nav-links{
    z-index: 5;
    position: fixed;
    height: 50vh;
    width: 100%;
    max-width: 100%;
    background: #161d31d4; // #14013ae5
    backdrop-filter: blur(5px);
    display: block;
    top: 59px;
    left: 0;
    left: -100%;
    overflow-y: auto;
    line-height: 50px;
    padding: 50px 10px;
    // box-shadow: 0px 15px 15px rgba(0,0,0,0.18);
    transition: all 0.3s ease;
  }
  #menu-btn:checked ~ .nav-links{
    left: 0%;
  }
  .nav-links::-webkit-scrollbar{
    width: 0px;
  }
  .nav-links li a{
    // display: flex;
    align-items: center;
    justify-content: center;
    display: block;
    font-size: 20px;
  }
  .nav-links li a span{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .nav-links .desktop-item{
    display: none;
  }
  .nav-links .mobile-item{
    display: block;
    font-size: 20px;
    color: #c8c8c8;
    // padding-left: 35.9%;
    cursor: pointer;
    font-weight: 500;
    border-radius: 5px;
    transition: all 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px 10px 0 0px;
    border-bottom: 1px solid white;
  }
  .nav-links .mobile-item:hover{
    background: #6c3bcccc; //#3A3B3C背景顏色
    color: #fff;
  }
  .content .row .mega-links{
    border-left: 0px;
    padding-left: 15px;
  }
  .row .mega-links li{
    margin: 0;
  }
  .content .row header{
    font-size: 19px;
  }
  .gotopicon{
    width: 50px;
    height: 50px;
  }
}
nav input{
  display: none;
}
.gotop{
  z-index: 5;
  position: fixed;
  // margin: 0 2% 3% 0px;
  margin-bottom: 43px;
  z-index: 1000;
  width: 50px;
  height: 50px;
  right:0;
  bottom: 0;
  display: none;
  // display: flex;
  margin-top: 50px;
  margin-right: 10px;
  .upload{
  z-index: 5;
  // background-color: red;
  }
}
@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
     }
}
</style>
