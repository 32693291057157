<template>
  <div
    class="misc-wrapper home-section"
    :style="{backgroundImage: `url(${require('@/assets/images/webp/bg-2.webp')})`}"
  >
    <div id="particles-js" />

    <div class="text">
      <transition name="bounce">
        <div>
          <h1 class="main-title text-light">
            歡迎，
            <span class="active-title">
              GameHost
            </span>
          </h1>
        </div>
      </transition>

      <div>
        <a
          class="btn"
          href="/auth"
        >
          開始使用
        </a>
      </div>

    </div>

  </div>
</template>

<script>
import { $themeConfig } from '@themeConfig'
import store from '@/store'
import 'particles.js/particles'

export default {
  metaInfo: {
    title: '首頁',
    titleTemplate: `%s - ${store.state.app.titleTemplate}`,
  },
  data() {
    return {
      anime: {
        particles: {
          number: {
            value: 80,
            density: {
              enable: true,
              value_area: 600,
            },
          },
          color: {
            value: '#ffffff',
          },
          shape: {
            type: 'circle',
            stroke: {
              width: 0,
              color: '#88008888',
            },
            polygon: {
              nb_sides: 5,
            },
            image: {
              src: 'img/github.svg',
              width: 100,
              height: 100,
            },
          },
          opacity: {
            value: 0.5,
            random: false,
            anim: {
              enable: false,
              speed: 1,
              opacity_min: 0.1,
              sync: false,
            },
          },
          size: {
            value: 3,
            random: true,
            anim: {
              enable: false,
              speed: 40,
              size_min: 0.1,
              sync: false,
            },
          },
          line_linked: {
            enable: true,
            distance: 300,
            color: '#8a66ad',
            opacity: 0,
            width: 2,
          },
          move: {
            enable: true,
            speed: 5,
            direction: 'none',
            random: false,
            straight: false,
            out_mode: 'out',
            bounce: false,
            attract: {
              enable: false,
              rotateX: 600,
              rotateY: 1200,
            },
          },
        },
        interactivity: {
          detect_on: 'canvas',
          events: {
            onhover: {
              enable: true,
              mode: 'grab',
            },
            onclick: {
              enable: false,
              mode: 'push',
            },
            resize: true,
          },
          modes: {
            grab: {
              distance: 140,
              line_linked: {
                opacity: 1,
              },
            },
            bubble: {
              distance: 400,
              size: 40,
              duration: 2,
              opacity: 8,
              speed: 3,
            },
            repulse: {
              distance: 200,
              duration: 0.4,
            },
            push: {
              particles_nb: 4,
            },
            remove: {
              particles_nb: 2,
            },
          },
        },
        retina_detect: true,
      },
    }
  },
  mounted() {
    this.initParticles()
  },
  methods: {
    // (初始化)動畫
    initParticles() {
      window.particlesJS('particles-js', this.anime)
    },
  },
  setup() {
    const { appName, appLogoImage } = $themeConfig.app
    return {
      appName,
      appLogoImage,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>

<style lang="scss" scope>
.home-section{
  width: 100vw;
  z-index: 2;
  position: relative;
  background-size:cover;
  background-repeat: no-repeat;
  height: 100vh;
  background-position:center;
  .brand-logo img {
    max-height: 60px;
  }
  .text{
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    flex-direction: column;
    z-index: 9998;
    margin-top: 100px;
    .main-title {
      font-size: 80px;
    }
    .sub-title {
      font-size: 30px;
    }

    .active-title {
      color: white;
      text-shadow: 0 0 0.05em #fff, 0 0 0.2em #f09819, 0 0 0.3em #f09819;
    }
  }
  .btn {
    // position: absolute !important;
    // bottom: 0;
    // margin-bottom: 80px;
    margin-top: 100px;

    --glow-color: rgb(217, 176, 255);
    --glow-spread-color: rgba(191, 123, 255, 0.781);
    --enhanced-glow-color: rgb(231, 206, 255);
    --btn-color: rgb(100, 61, 136);
    border: .25em solid var(--glow-color);
    padding: 1em 2em;
    color: var(--glow-color);
    font-size: 18px;
    font-weight: bold;
    background-color: var(--btn-color);
    border-radius: 1em;
    outline: none;
    box-shadow: 0 0 1em .25em var(--glow-color), 0 0 4em 1em var(--glow-spread-color), inset 0 0 .75em .25em var(--glow-color);
    text-shadow: 0 0 .5em var(--glow-color);
    position: relative;
    transition: all 0.3s;
  }

  .btn::after {
    pointer-events: none;
    content: "";
    position: absolute;
    top: 120%;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: var(--glow-spread-color);
    filter: blur(2em);
    opacity: .7;
    transform: perspective(1.5em) rotateX(35deg) scale(1, .6);
  }

  .btn:hover {
    color: var(--btn-color);
    background-color: var(--glow-color);
    box-shadow: 0 0 1em .25em var(--glow-color), 0 0 4em 2em var(--glow-spread-color), inset 0 0 .75em .25em var(--glow-color);
  }

  .btn:active {
    box-shadow: 0 0 0.6em .25em var(--glow-color), 0 0 2.5em 2em var(--glow-spread-color), inset 0 0 .5em .25em var(--glow-color);
  }

  #particles-js {
    position: absolute;
    width: 100%;
    height: 98vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
  }
}
// 動畫
div{
  animation: fadein 2s ;
}

@media only screen and (max-width: 680px){
  .home-section{
    .text {
      .main-title {
        font-size: 40px;
      }
    }
    .brand-logo img {
      max-height: 40px;
    }

    .btn {
      padding: 18px 30px;
      font-size: 15px;
    }
  }
}

@keyframes fadein {
    0% { opacity: 0;}
    100% { opacity: 1; }
}
</style>
